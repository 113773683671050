import axios from "axios";

const baseURL = process.env.REACT_APP_PUBLIC_API_URL;

const $host = axios.create({
  baseURL,
});

const $authHost = axios.create({
  baseURL,
});

const authInterceptor = (config) => {
  const token =
    localStorage.getItem("access_token") ||
    sessionStorage.getItem("access_token");
  config.headers["Authorization"] = token;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };
