import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoadingDelete: false,
  residentialPlans: [],
  featuresList: [],
};

const residentialSubsPlansSlice = createSlice({
  name: "residentialSubsPlans",
  initialState,
  reducers: {
    // get Features list
    getResidentialSubsPlansFeatures: (state) => {
      state.isLoading = true;
    },
    getResidentialSubsPlansFeaturesSuccess: (state, action) => {
      state.isLoading = false;
      state.featuresList = action.payload;
    },
    // get Residential Subs
    getResidentialSubsPlans: (state) => {
      state.isLoading = true;
    },
    getResidentialSubsPlansSuccess: (state, action) => {
      state.isLoading = false;
      state.residentialPlans = action.payload;
    },
    // create Residential subs
    createResidentialSubsPlan: (state) => {
      state.isLoading = true;
    },
    // delete Residential plans
    deleteResidentialSubsPlan: (state) => {
      state.isLoadingDelete = true;
    },
    // change plan status
    changeResidentialPlanStatus: () => {},
    // Update Residential subs
    updateResidentialSubsPlan: (state) => {
      state.isLoading = true;
    },
    // reset states
    resetResidentialSubsPlansStates: (state) => {
      state.isLoading = false;
      state.isLoadingDelete = false;
    },
  },
});

export const {
  getResidentialSubsPlansFeatures,
  getResidentialSubsPlansFeaturesSuccess,
  getResidentialSubsPlans,
  createResidentialSubsPlan,
  deleteResidentialSubsPlan,
  changeResidentialPlanStatus,
  updateResidentialSubsPlan,
  getResidentialSubsPlansSuccess,
  resetResidentialSubsPlansStates,
} = residentialSubsPlansSlice.actions;
export default residentialSubsPlansSlice.reducer;
