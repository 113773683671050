import { combineReducers } from "redux";
import authReducer from "./auth/authSlice";
import profileSettingsSlice from "./profileSettings/profileSettingsSlice";
import residentialSubsPlansSlice from "./residentialSubsPlans/residentialSubsPlansSlice";
import passwordChangeSlice from "./passwordChange/passwordChangeSlice";
import LayoutsReducer from "./features/layout";
import CardsReducer from "./features/cards";
import MessengerReducer from "./features/messenger";
import residentialAnalyticsSlice from "./residentialAnalytics/residentialAnalyticsSlice";
import residentialUsersSlice from "./residentialUsers/residentialUsersSlice";
import manufacturersSlice from "./manufacturers/manufacturersSlice";
import dashboardSlice from "./dashboard/dashboardSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  profileSettings: profileSettingsSlice,
  residentialSubsPlans: residentialSubsPlansSlice,
  passwordChange: passwordChangeSlice,
  residentialAnalytics: residentialAnalyticsSlice,
  residentialUsers: residentialUsersSlice,
  radiatorManufacturers: manufacturersSlice,
  dashboard: dashboardSlice,
  // prev
  layout: LayoutsReducer,
  cards: CardsReducer,
  messenger: MessengerReducer,
});

export default rootReducer;
