import { takeLatest, put } from "redux-saga/effects";
import { $host, $authHost } from "../axios";

import {
  getManufacturers,
  createManufacturers,
  deleteManufacturers,
  updateManufacturers,
  getManufacturersSuccess,
  toggleManufacturerStatus,
  resetManufacturersStates,
} from "./manufacturersSlice";

// get residential plans
function* getManufacturersSaga(action) {
  let url = `/radiators/manufacturers`;
  if (action?.payload?.search) {
    url += `?search=${encodeURIComponent(action?.payload?.search)}`;
  }

  try {
    const response = yield $authHost.get(url);
    yield put(getManufacturersSuccess(response.data.data.manufacturers));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
      yield put(resetManufacturersStates());
    } else {
      yield put(resetManufacturersStates());
    }
  }
}

// create residential plans
function* createManufacturersSaga(action) {
  const { formState, notify, navigate } = action.payload;

  const formData = new FormData();
  formData.append("title", formState.title);
  formData.append("website", formState.url_path);
  formData.append("info", formState.info);
  formData.append("logo", formState.image);

  try {
    const response = yield $authHost.post(`/radiators/manufacturers`, formData);
    notify(response.data.description, "success");
    navigate("/radiator-manufactures");
    yield put(resetManufacturersStates());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetManufacturersStates());
    } else {
      notify(error.message, "error");
      yield put(resetManufacturersStates());
    }
  }
}
// delete plan
function* deleteManufacturersSaga(action) {
  const { manufacturersId, notify, handleCloseDeleteModal } = action.payload;

  try {
    const response = yield $authHost.delete(
      `/radiators/manufacturers/${manufacturersId}`
    );
    // console.log("emptydescriptiondescription", response.data.description);
    notify("Successfully deleted a manufacturer", "success");
    handleCloseDeleteModal();
    yield put(resetManufacturersStates());
    yield put(getManufacturers());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetManufacturersStates());
    } else {
      notify(error.message, "error");
      yield put(resetManufacturersStates());
    }
  }
}

// update plan
function* updateManufacturersSaga(action) {
  const { formState, menufacturerId, notify, navigate } = action.payload;

  const formData = new FormData();
  formData.append("title", formState.title);
  formData.append("website", formState.url_path);
  formData.append("info", formState.info);
  // formData.append("logo", formState.image);

  try {
    const response = yield $authHost.post(
      `/radiators/manufacturers/${menufacturerId}`,
      formData
    );
    notify(response.data.description, "success");
    navigate("/radiator-manufactures");
    yield put(resetManufacturersStates());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetManufacturersStates());
    } else {
      notify(error.message, "error");
      yield put(resetManufacturersStates());
    }
  }
}

// status update 
function* toggleManufacturerStatusSaga(action){
  const { manufacturer_id, status, notify } = action.payload;
  try {
    const response = yield $authHost.put(
      `/radiators/manufacturers/toggle-status/${manufacturer_id}/${status}`
    );
    notify(response.data.description, "success");
    yield put(resetManufacturersStates());
    yield put(getManufacturers());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetManufacturersStates());
    } else {
      notify(error.message, "error");
      yield put(resetManufacturersStates());
    }
  }
}

export default function* manufacturerRootSaga() {
  yield takeLatest(getManufacturers.type, getManufacturersSaga);
  yield takeLatest(createManufacturers.type, createManufacturersSaga);
  yield takeLatest(deleteManufacturers.type, deleteManufacturersSaga);
  yield takeLatest(updateManufacturers.type, updateManufacturersSaga);
  yield takeLatest(toggleManufacturerStatus.type, toggleManufacturerStatusSaga);
  
}
