import { createSlice } from "@reduxjs/toolkit";
import { AES, enc } from "crypto-js";
let currentUserFromLocalStorage = null;
const encryptedUser = localStorage.getItem("currentUser");

if (encryptedUser) {
  try {
    const decryptedUserBytes = AES.decrypt(
      encryptedUser,
      process.env.REACT_APP_USER_DATA_ENC_KEY
    );
    const decryptedUserString = decryptedUserBytes.toString(enc.Utf8);
    const storedUserData = JSON.parse(decryptedUserString);

    const storedTimestamp = storedUserData.timestamp;
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - storedTimestamp;
    const oneDayInMilliseconds = 1 * 24 * 60 * 60 * 1000;

    if (timeDifference > oneDayInMilliseconds) {
      localStorage.removeItem("currentUser");
    } else {
      const storedUser = JSON.parse(storedUserData.data);
      currentUserFromLocalStorage = storedUser;
    }
  } catch (error) {
    console.error("Error decrypting user data:", error);
  }
}

const initialState = {
  user: currentUserFromLocalStorage,
  isLoading: false,
  success: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // login
    loginStart: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
    },

    // logout
    logout: (state) => {
      state.user = null;
    },
    // AuthMe
    authMe: (state) => {
      state.isLoading = true;
    },
    authMeSuccess: (state, action) => {
      state.user = action.payload;
    },
    // reset states
    resetAuthStates: (state) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  logout,
  authMe,
  authMeSuccess,
  resetAuthStates,
} = authSlice.actions;
export default authSlice.reducer;
