import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

const passwordChangeSlice = createSlice({
  name: "passwordChange",
  initialState,
  reducers: {
    // Change Password
    changePassword: (state) => {
      state.isLoading = true;
    },

    // reset states
    resetChangePasswordStates: (state) => {
      state.isLoading = false;
    },
  },
});

export const { changePassword, resetChangePasswordStates } =
  passwordChangeSlice.actions;
export default passwordChangeSlice.reducer;
