export const preventDefault = () => {
  document.querySelectorAll("form").forEach((form) => {
    form.addEventListener("submit", (e) => e.preventDefault());
  });
  document.querySelectorAll('a[href="#"]').forEach((a) => {
    a.addEventListener("click", (e) => e.preventDefault());
  });
};

export function generateAlphabet() {
  return [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
}

export function truncateWordsString(String, maxLength) {
  const words = String.split(" ");
  if (words.length > maxLength) {
    return words.slice(0, maxLength).join(" ") + "...";
  } else {
    return String;
  }
}


export function truncateCharsString(string, maxLength) {
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + "...";
  } else {
    return string;
  }
}



export async function fetchAndConvertToBlob(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = url.substring(url.lastIndexOf('/') + 1); 
    const file = new File([blob], filename, { type: blob.type });
    return file;
  } catch (error) {
    console.error('Error fetching or converting image:', error);
    return null;
  }
}