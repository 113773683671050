import { takeLatest, put } from "redux-saga/effects";
import { $host, $authHost } from "../axios";
import {
  updateProfileSettings,
  resetzProfileSettingsStates,
} from "./profileSettingsSlice";
import { authMe } from "@store/auth/authSlice";
// login
function* updateProfileSaga(action) {
  const { formState, notify } = action.payload;
  const payload = {
    first_name: formState.firstName,
    last_name: formState.lastName,
    mobile: formState.phone,
    address_line1: formState.address_line1,
    address_line2: formState.address_line2,
    town: formState.town,
    city: formState.city,
    post_code: formState.post_code,
  };

  try {
    const response = yield $authHost.post(`/auth/update_profile`, payload);
    notify(response.data.description, "success");
    yield put(resetzProfileSettingsStates());
    yield put(authMe());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetzProfileSettingsStates());
    } else {
      notify(error.message, "error");
      yield put(resetzProfileSettingsStates());
    }
  }
}

export default function* profileSettingsSaga() {
  yield takeLatest(updateProfileSettings.type, updateProfileSaga);
}
