import { takeLatest, put } from "redux-saga/effects";
import { $host, $authHost } from "../axios";
import {
  getResidentialAnalytics,
  getResidentialAnalyticsSuccess,
  getRoomAnalytics,
  getRoomAnalyticsSuccess,
  resetResidentialAnalyticsStates,
} from "./residentialAnalyticsSlice";
import { authMe } from "@store/auth/authSlice";

function* getResidentialAnalyticsSaga(action) {
  const { userId, formState, currentYearFull, month, week, date } =
    action.payload;

  let url = "/analytics/admin";

  if (formState.chartPeriod && formState.chartType) {
    const params = new URLSearchParams();
    params.append("userId", userId);
    params.append("period", formState.chartPeriod.toLowerCase());
    params.append("year", currentYearFull);
    params.append("month", month);
    params.append("week", week);
    params.append("date", date);

    url += `?${params.toString()}`;
  }

  try {
    const response = yield $authHost.get(url);
    yield put(getResidentialAnalyticsSuccess(response.data.data.analytics));
    yield put(resetResidentialAnalyticsStates());
    yield put(authMe());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
      yield put(resetResidentialAnalyticsStates());
    } else {
      console.error(error);
      yield put(resetResidentialAnalyticsStates());
    }
  }
}

function* getRoomsUsageAnalyticsSaga(action) {
  try {
    const { userId, year, month, start_date, end_date, period } =
      action.payload;
    const params = { userId, year, month, start_date, end_date, period };
    const response = yield $authHost.get(
      `/analytics/admin/rooms-radiator-usage`,
      { params }
    );

    yield put(getRoomAnalyticsSuccess(response.data.data.analytics ?? []));
    yield put(resetResidentialAnalyticsStates());
    yield put(authMe());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
      yield put(resetResidentialAnalyticsStates());
    } else {
      console.error(error);
      yield put(resetResidentialAnalyticsStates());
    }
  }
}

export default function* residentialAnalyticsRootSaga() {
  yield takeLatest(getResidentialAnalytics.type, getResidentialAnalyticsSaga);
  yield takeLatest(getRoomAnalytics.type, getRoomsUsageAnalyticsSaga);
}
