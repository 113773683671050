import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoadingDelete: false,
  residentialUsers: [],
};

const residentialUsersSlice = createSlice({
  name: "residentialUsers",
  initialState,
  reducers: {
    // get Residential users
    getResidentialUsers: (state) => {
      state.isLoading = true;
    },
    getResidentialUsersSuccess: (state, action) => {
      state.isLoading = false;
      state.residentialUsers = action.payload;
    },
    // delete Residential users
    deleteResidentialUsers: (state) => {
      state.isLoadingDelete = true;
    },
    // change users status
    changeResidentialUsersStatus: () => {},

    // reset states
    resetResidentialUsersStates: (state) => {
      state.isLoading = false;
      state.isLoadingDelete = false;
    },
  },
});

export const {
  getResidentialUsers,
  getResidentialUsersSuccess,
  deleteResidentialUsers,
  changeResidentialUsersStatus,
  resetResidentialUsersStates,
} = residentialUsersSlice.actions;
export default residentialUsersSlice.reducer;
