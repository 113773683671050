import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoadingDelete: false,
  manufacturers: [],
};

const manufacturersSlice = createSlice({
  name: "manufacturers",
  initialState,
  reducers: {
    // get Residential Subs
    getManufacturers: (state) => {
      state.isLoading = true;
    },
    getManufacturersSuccess: (state, action) => {
      state.isLoading = false;
      state.manufacturers = action.payload;
    },
    // create Residential subs
    createManufacturers: (state) => {
      state.isLoading = true;
    },
    // delete Residential plans
    deleteManufacturers: (state) => {
      state.isLoadingDelete = true;
    },
    // Update Residential subs
    updateManufacturers: (state) => {
      state.isLoading = true;
    },
    // toggle status
    toggleManufacturerStatus: (state, action) => {},

    // reset states
    resetManufacturersStates: (state) => {
      state.isLoading = false;
      state.isLoadingDelete = false;
    },
  },
});

export const {
  getManufacturers,
  createManufacturers,
  deleteManufacturers,
  updateManufacturers,
  getManufacturersSuccess,
  toggleManufacturerStatus,
  resetManufacturersStates,
} = manufacturersSlice.actions;
export default manufacturersSlice.reducer;
