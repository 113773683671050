import { lazy } from "react";
import { Navigate } from "react-router-dom";
// Lyaouts
const AppLayout = lazy(() => import("@layout/AppLayout"));
const AuthLayout = lazy(() => import("@layout/AuthLayout"));
const BlankLayout = lazy(() => import("@layout/BlankLayout"));
// pages
const LoginPage = lazy(() => import("@pages/Authentication/login"));
const Dashboard = lazy(() => import("@pages/dashboard/dashboard"));
const ResidentialUsersList = lazy(() =>
  import("@pages/residentials/users/usersList")
);

const ViewResidentialUser = lazy(() =>
  import("@pages/residentials/users/viewUserDetails")
);

const ViewRoomsDetails = lazy(() =>
  import("@pages/residentials/users/roomsDetails")
);

const ResidentialSubscriptionList = lazy(() => {
  return import("@pages/residentials/plans/subscriptionsPlans").catch(
    (error) => {
      console.error("Error loading component:", error);
      throw error;
    }
  );
});

const ResidentialSubscriptionDetails = lazy(() => {
  return import("@pages/residentials/plans/subscriptionDetail").catch(
    (error) => {
      console.error("Error loading component:", error);
      throw error;
    }
  );
});

const AddNewResidentialPlans = lazy(() => {
  return import("@pages/residentials/plans/addNewPlan").catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const CommercialClientsList = lazy(() => {
  return import("@pages/commercials/clients/clients").catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const CommercialSubscriptionList = lazy(() => {
  return import("@pages/commercials/plans/subscriptionsPlans").catch(
    (error) => {
      console.error("Error loading component:", error);
      throw error;
    }
  );
});

const CommercialSubscriptionDetails = lazy(() => {
  return import("@pages/commercials/plans/subscriptionDetail").catch(
    (error) => {
      console.error("Error loading component:", error);
      throw error;
    }
  );
});

// rooms analytics
const RoomsAnalytics = lazy(() => {
  return import("@pages/roomsAnalytics/radiatorUsageRooms").catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const RadiatorUsagePerRoomAnalytics = lazy(() => {
  return import("@pages/roomsAnalytics/radiatorUsagePerRoom").catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

// detail commercial dashboard charts
const CommercialDetailedRevenueChart = lazy(() => {
  return import(
    "@widgets/Dashboard/detailedCommercialCharts/detailedRevenueChart"
  ).catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const CommercialDetailedSignUpChart = lazy(() => {
  return import(
    "@widgets/Dashboard/detailedCommercialCharts/detailedSignUpChart"
  ).catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const CommercialDetailedSubScriptionChart = lazy(() => {
  return import(
    "@widgets/Dashboard/detailedCommercialCharts/detailedSubscriptionChart"
  ).catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

// detail residential dashboard charts
const ResidentialDetailedRevenueChart = lazy(() => {
  return import(
    "@widgets/Dashboard/detailedResidendialsCharts/detailedRevenueChart"
  ).catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const ResidentialDetailedSignUpChart = lazy(() => {
  return import(
    "@widgets/Dashboard/detailedResidendialsCharts/detailedSignUpChart"
  ).catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const ResidentialDetailedSubScriptionChart = lazy(() => {
  return import(
    "@widgets/Dashboard/detailedResidendialsCharts/detailedSubscriptionChart"
  ).catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const RadiatorManufactures = lazy(() => {
  return import("@pages/RadiatorManufactures/RadiatorManufactures").catch(
    (error) => {
      console.error("Error loading component:", error);
      throw error;
    }
  );
});

const RadiatorManufacturesList = lazy(() => {
  return import("@pages/RadiatorManufactures/RadiatorManufacturesList").catch(
    (error) => {
      console.error("Error loading component:", error);
      throw error;
    }
  );
});

const AddNewCommercialPlans = lazy(() => {
  return import("@pages/commercials/plans/addNewPlan").catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

const CreateClientForm = lazy(() => {
  return import("@pages/commercials/clients/createClientForm").catch(
    (error) => {
      console.error("Error loading component:", error);
      throw error;
    }
  );
});

// Analytics Charts
const AnalyticsChart = lazy(() => {
  return import("@pages/residentials/analytics").catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

// password change
const ChangePassword = lazy(() => {
  return import("@pages/changePassword").catch((error) => {
    console.error("Error loading component:", error);
    throw error;
  });
});

// prev
const DashboardA = lazy(() => import("@pages/DashboardA"));
const DashboardB = lazy(() => import("@pages/DashboardB"));
const DashboardC = lazy(() => import("@pages/DashboardC"));
const DashboardD = lazy(() => import("@pages/DashboardD"));
const DashboardE = lazy(() => import("@pages/DashboardE"));
const DashboardF = lazy(() => import("@pages/DashboardF"));
const DashboardG = lazy(() => import("@pages/DashboardG"));
const DashboardH = lazy(() => import("@pages/DashboardH"));
const DashboardI = lazy(() => import("@pages/DashboardI"));
const DashboardJ = lazy(() => import("@pages/DashboardJ"));
const DashboardK = lazy(() => import("@pages/DashboardK"));
const DoctorAppointments = lazy(() => import("@pages/DoctorAppointments"));
const PatientAppointments = lazy(() => import("@pages/PatientAppointments"));
const Patients = lazy(() => import("@pages/Patients"));
const Tests = lazy(() => import("@pages/Tests"));
const Doctors = lazy(() => import("@pages/Doctors"));
const StaffList = lazy(() => import("@pages/Staff"));
const DoctorMessenger = lazy(() => import("@pages/DoctorMessenger"));
const PatientMessenger = lazy(() => import("@pages/PatientMessenger"));
const DoctorsReviews = lazy(() => import("@pages/DoctorsReviews"));
const PatientReviews = lazy(() => import("@pages/PatientReviews"));
const Finances = lazy(() => import("@pages/Finances"));
const Settings = lazy(() => import("@pages/Settings"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));

const AppRoutes = (user) => [
  {
    element: user ? <AppLayout /> : <Navigate to="/" />,
    private: true,
    children: [
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      {
        path: "/residential_users",
        exact: true,
        element: <ResidentialUsersList />,
      },
      {
        path: "/residential_users_details",
        exact: true,
        element: <ViewResidentialUser />,
      },
      {
        path: "/rooms_details",
        exact: true,
        element: <ViewRoomsDetails />,
      },
      {
        path: "/residential_subscriptions",
        exact: true,
        element: <ResidentialSubscriptionList />,
      },
      {
        path: "/residential_subscriptions_details",
        exact: true,
        element: <ResidentialSubscriptionDetails />,
      },
      {
        path: "/add_residential_subscriptions",
        exact: true,
        element: <AddNewResidentialPlans />,
      },
      {
        path: "/commercial_clients",
        exact: true,
        element: <CommercialClientsList />,
      },
      {
        path: "/commercial_clients_add",
        exact: true,
        element: <CreateClientForm />,
      },
      {
        path: "/commercial_subscriptions",
        exact: true,
        element: <CommercialSubscriptionList />,
      },
      {
        path: "/commercial_subscriptions_details",
        exact: true,
        element: <CommercialSubscriptionDetails />,
      },
      {
        path: "/add_commercial_subscriptions",
        exact: true,
        element: <AddNewCommercialPlans />,
      },
      // detailed commercial dashboard analytics
      {
        path: "/commercial_clients_revenue_chart",
        exact: true,
        element: <CommercialDetailedRevenueChart />,
      },
      {
        path: "/commercial_clients_signup_chart",
        exact: true,
        element: <CommercialDetailedSignUpChart />,
      },
      {
        path: "/commercial_clients_subscription_chart",
        exact: true,
        element: <CommercialDetailedSubScriptionChart />,
      },
      // detailed residentail dashboard analytics
      {
        path: "/residential_users_revenue_chart",
        exact: true,
        element: <ResidentialDetailedRevenueChart />,
      },
      {
        path: "/residential_users_signups",
        exact: true,
        element: <ResidentialDetailedSignUpChart />,
      },
      {
        path: "/residential_users_subscription_chart",
        exact: true,
        element: <ResidentialDetailedSubScriptionChart />,
      },
      // radiator Manufactors
      {
        path: "/radiator-manufactures",
        exact: true,
        element: <RadiatorManufacturesList />,
      },
      {
        path: "/radiators-manufactures",
        exact: true,
        element: <RadiatorManufactures />,
      },
      // radiator usage Rooms analytics
      {
        path: "/rooms-analytics",
        exact: true,
        element: <RoomsAnalytics />,
      },
      {
        path: "/per-room-analytics",
        exact: true,
        element: <RadiatorUsagePerRoomAnalytics />,
      },

      {
        path: "/radiator-manufactures",
        exact: true,
        element: <RadiatorManufacturesList />,
      },
      // Analytics Charts
      {
        path: "/residential_analytics",
        exact: true,
        element: <AnalyticsChart />,
      },
      {
        path: "/settings",
        exact: true,
        element: <ChangePassword />,
      },
      { path: "/dashboard_a", exact: true, element: <DashboardA /> },
      { path: "/dashboard_b", exact: true, element: <DashboardB /> },
      { path: "/dashboard_c", exact: true, element: <DashboardC /> },
      { path: "/dashboard_d", exact: true, element: <DashboardD /> },
      { path: "/dashboard_e", exact: true, element: <DashboardE /> },
      { path: "/dashboard_f", exact: true, element: <DashboardF /> },
      { path: "/dashboard_g", exact: true, element: <DashboardG /> },
      { path: "/dashboard_h", exact: true, element: <DashboardH /> },
      { path: "/dashboard_i", exact: true, element: <DashboardI /> },
      { path: "/dashboard_j", exact: true, element: <DashboardJ /> },
      { path: "/dashboard_k", exact: true, element: <DashboardK /> },
      {
        path: "/doctor_appointments",
        exact: true,
        element: <DoctorAppointments />,
      },
      {
        path: "/patient_appointments",
        exact: true,
        element: <PatientAppointments />,
      },
      { path: "/patients", exact: true, element: <Patients /> },
      { path: "/tests", exact: true, element: <Tests /> },
      { path: "/doctors", exact: true, element: <Doctors /> },
      { path: "/staff", exact: true, element: <StaffList /> },
      { path: "/doctor_messenger", exact: true, element: <DoctorMessenger /> },
      {
        path: "/patient_messenger",
        exact: true,
        element: <PatientMessenger />,
      },
      { path: "/doctor_reviews", exact: true, element: <DoctorsReviews /> },
      { path: "/patient_reviews", exact: true, element: <PatientReviews /> },
      { path: "/finances", exact: true, element: <Finances /> },
      // { path: "/settings", exact: true, element: <Settings /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "/404", exact: true, element: <PageNotFound /> },
      { path: "/", exact: true, element: <LoginPage /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export { AppRoutes };
