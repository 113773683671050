import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  dashboardData: {},
  signUps: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // get Dashboard
    getDashboardData: (state) => {
      state.isLoading = true;
    },
    getDashboardDataSuccess: (state, action) => {
      state.isLoading = false;
      state.dashboardData = action.payload;
    },

    // Revenue
    // Subscriptions

    // Sign-ups
    getSignUps: (state) => {
      state.isLoading = true;
    },

    getSignUpsSuccess: (state, action) => {
      state.isLoading = false;
      state.signUps = action.payload;
    },

    // reset states
    resetDashboardDataStates: (state) => {
      state.isLoading = false;
      state.isLoadingDelete = false;
    },
  },
});

export const {
  getDashboardData,
  getDashboardDataSuccess,
  // Sign-ups
  getSignUps,
  getSignUpsSuccess,
  //
  resetDashboardDataStates,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
