import { takeLatest, put } from "redux-saga/effects";
import { $host, $authHost } from "../axios";
import {
  getResidentialUsers,
  getResidentialUsersSuccess,
  deleteResidentialUsers,
  changeResidentialUsersStatus,
  resetResidentialUsersStates,
} from "./residentialUsersSlice";

// get residential users
function* getResidentialUsersSaga(action) {
  let url = "/users";

  if (action?.payload?.search) {
    url += `?search=${encodeURIComponent(action?.payload?.search)}`;
  }

  try {
    const response = yield $authHost.get(url);
    yield put(getResidentialUsersSuccess(response.data.data.users));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
    }
    yield put(resetResidentialUsersStates());
  }
}

// delete users
function* deleteResidentialUsersSaga(action) {
  const { planId, notify, handleCloseDeleteModal } = action.payload;

  try {
    const response = yield $authHost.delete(`//${planId}`);
    notify(response.data.description, "success");
    handleCloseDeleteModal();
    yield put(resetResidentialUsersStates());
    yield put(getResidentialUsers());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetResidentialUsersStates());
    } else {
      notify(error.message, "error");
      yield put(resetResidentialUsersStates());
    }
  }
}
// change status
function* changeResidentialUsersSaga(action) {
  const { user_id, status, notify } = action.payload;

  try {
    const response = yield $authHost.put(
      `/users/toggle-status/${user_id}/${status}`
    );
    notify(response.data.description, "success");
    yield put(resetResidentialUsersStates());
    yield put(getResidentialUsers());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetResidentialUsersStates());
    } else {
      notify(error.message, "error");
      yield put(resetResidentialUsersStates());
    }
  }
}

export default function* residentialUsersSaga() {
  yield takeLatest(getResidentialUsers.type, getResidentialUsersSaga);
  yield takeLatest(deleteResidentialUsers.type, deleteResidentialUsersSaga);
  yield takeLatest(
    changeResidentialUsersStatus.type,
    changeResidentialUsersSaga
  );
}
