import { takeLatest, put } from "redux-saga/effects";
import { $host, $authHost } from "../axios";
import {
  getResidentialSubsPlansFeatures,
  getResidentialSubsPlansFeaturesSuccess,
  getResidentialSubsPlans,
  createResidentialSubsPlan,
  deleteResidentialSubsPlan,
  changeResidentialPlanStatus,
  updateResidentialSubsPlan,
  getResidentialSubsPlansSuccess,
  resetResidentialSubsPlansStates,
} from "./residentialSubsPlansSlice";

import { mapSubscriptionTypeToValue } from "@utils/constants";

// get residential plans features
function* getResidentialSubsPlansFeaturesSaga(action) {
  let url = `/subscriptions/features-list`;
  if (action?.payload?.search) {
    url += `?search=${encodeURIComponent(action?.payload?.search)}`;
  }

  try {
    const response = yield $authHost.get(url);
    yield put(
      getResidentialSubsPlansFeaturesSuccess(
        response.data.data.subscription_plans_features
      )
    );
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
      yield put(resetResidentialSubsPlansStates());
    } else {
      yield put(resetResidentialSubsPlansStates());
    }
  }
}

// get residential plans
function* getResidentialSubsPlansSaga(action) {
  let url = `/subscriptions`;
  if (action?.payload?.search) {
    url += `?search=${encodeURIComponent(action?.payload?.search)}`;
  }

  try {
    const response = yield $authHost.get(url);
    yield put(getResidentialSubsPlansSuccess(response.data.data.plans));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
      yield put(resetResidentialSubsPlansStates());
    } else {
      yield put(resetResidentialSubsPlansStates());
    }
  }
}

// create residential plans
function* createResidentialSubsPlansaga(action) {
  const { formState, notify, navigate } = action.payload;

  const payload = {
    title: formState.planTitle,
    type: mapSubscriptionTypeToValue(formState.subscriptionType),
    cost: +formState.planCost,
    yearly_discount: +formState.yearlyDiscount,
    subscription_plans_features: formState.subscriptionFeatures,
  };

  try {
    const response = yield $authHost.post(`/subscriptions`, payload);
    notify(response.data.description, "success");
    navigate("/residential_subscriptions");
    yield put(resetResidentialSubsPlansStates());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetResidentialSubsPlansStates());
    } else {
      notify(error.message, "error");
      yield put(resetResidentialSubsPlansStates());
    }
  }
}
// delete plan
function* deleteResidentialSubsPlansaga(action) {
  const { planId, notify, handleCloseDeleteModal } = action.payload;

  try {
    const response = yield $authHost.delete(`/subscriptions/${planId}`);
    notify(response.data.description, "success");
    handleCloseDeleteModal();
    yield put(resetResidentialSubsPlansStates());
    yield put(getResidentialSubsPlans());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetResidentialSubsPlansStates());
    } else {
      notify(error.message, "error");
      yield put(resetResidentialSubsPlansStates());
    }
  }
}
// change status
function* changeResidentialStatusaga(action) {
  const { planId, status, notify } = action.payload;

  try {
    const response = yield $authHost.put(`/subscriptions/${planId}/${status}`);
    notify(response.data.description, "success");
    yield put(resetResidentialSubsPlansStates());
    yield put(getResidentialSubsPlans());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetResidentialSubsPlansStates());
    } else {
      notify(error.message, "error");
      yield put(resetResidentialSubsPlansStates());
    }
  }
}

// update plan
function* updateResidentialSubsPlansaga(action) {
  const { formState, planId, notify, navigate } = action.payload;

  const payload = {
    title: formState.planTitle,
    type: mapSubscriptionTypeToValue(formState.subscriptionType),
    cost: +formState.planCost,
    yearly_discount: +formState.yearlyDiscount,
    subscription_plans_features: formState.subscriptionFeatures,
  };

  try {
    const response = yield $authHost.post(`/subscriptions/${planId}`, payload);
    notify(response.data.description, "success");
    navigate("/residential_subscriptions");
    yield put(resetResidentialSubsPlansStates());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetResidentialSubsPlansStates());
    } else {
      notify(error.message, "error");
      yield put(resetResidentialSubsPlansStates());
    }
  }
}

export default function* residentialSubsPlansSaga() {
  yield takeLatest(getResidentialSubsPlans.type, getResidentialSubsPlansSaga);
  yield takeLatest(
    getResidentialSubsPlansFeatures.type,
    getResidentialSubsPlansFeaturesSaga
  );
  yield takeLatest(
    createResidentialSubsPlan.type,
    createResidentialSubsPlansaga
  );
  yield takeLatest(
    deleteResidentialSubsPlan.type,
    deleteResidentialSubsPlansaga
  );
  yield takeLatest(
    changeResidentialPlanStatus.type,
    changeResidentialStatusaga
  );
  yield takeLatest(
    updateResidentialSubsPlan.type,
    updateResidentialSubsPlansaga
  );
}
