import { all } from "redux-saga/effects";
import authSaga from "./auth/authSaga";
import profileSettingsSaga from "./profileSettings/profileSettingsSaga";
import residentialSubsPlansSaga from "./residentialSubsPlans/residentialSubsPlansSaga";
import residentialAnalyticsRootSaga from "./residentialAnalytics/residentialAnalyticsSaga";
import passwordChangeRootSaga from "./passwordChange/passwordChangeSaga";
import residentialUsersSaga from "./residentialUsers/residentialUsersSaga";
import manufacturerRootSaga from "./manufacturers/manufacturersSaga";
import dashboardRootSaga from "./dashboard/dashboardSaga";
export default function* rootSaga() {
  yield all([
    authSaga(),
    profileSettingsSaga(),
    residentialSubsPlansSaga(),
    residentialAnalyticsRootSaga(),
    residentialUsersSaga(),
    manufacturerRootSaga(),
    passwordChangeRootSaga(),
    dashboardRootSaga(),
  ]);
}
