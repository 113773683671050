import { takeLatest, put } from "redux-saga/effects";
import { AES, enc } from "crypto-js";
import { $host, $authHost } from "../axios";
import {
  loginStart,
  loginSuccess,
  logout,
  authMe,
  authMeSuccess,
  resetAuthStates,
} from "./authSlice";

// login
function* loginSaga(action) {
  const { emailPwd, notify, startTransition, navigate } = action.payload;
  try {
    const response = yield $host.post("/auth/login", {
      email: emailPwd.email,
      password: emailPwd.password,
    });

    if (
      response.data.data.user.level === 1001 ||
      response.data.data.user.level === 2001
    ) {
      const currentUser = response.data.data.user;
      yield put(loginSuccess(currentUser));
      const userString = JSON.stringify(currentUser);

      const timestamp = new Date().getTime();

      const userData = {
        data: userString,
        timestamp,
      };

      const encryptedUser = AES.encrypt(
        JSON.stringify(userData),
        process.env.REACT_APP_USER_DATA_ENC_KEY
      ).toString();

      localStorage.setItem("currentUser", encryptedUser);
      localStorage.setItem("access_token", currentUser?.access_token);
      sessionStorage.setItem("access_token", currentUser?.access_token);
      notify(response.data.description, "success");
      startTransition(() => {
        navigate("/dashboard");
      });
      yield put(resetAuthStates());
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetAuthStates());
    } else {
      notify(error.description, "error");
      yield put(resetAuthStates());
    }
  }
}

// logout
function* logoutSaga() {
  yield localStorage.removeItem("currentUser");
  yield localStorage.removeItem("access_token");
  yield sessionStorage.removeItem("access_token");
}
// authMeSaga
function* authMeSaga() {
  try {
    const response = yield $authHost.get("/auth/me");
    const userString = JSON.stringify(response.data.data.me);
    yield put(authMeSuccess(response.data.data.me));
    const timestamp = new Date().getTime();

    const userData = {
      data: userString,
      timestamp,
    };

    const encryptedUser = AES.encrypt(
      JSON.stringify(userData),
      process.env.REACT_APP_USER_DATA_ENC_KEY
    ).toString();

    localStorage.setItem("currentUser", encryptedUser);
    yield put(resetAuthStates());
  } catch (error) {
    yield put(resetAuthStates());
  }
}

export default function* authSaga() {
  yield takeLatest(loginStart.type, loginSaga);
  yield takeLatest(logout.type, logoutSaga);
  yield takeLatest(authMe.type, authMeSaga);
}
