import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

const profileSettingsSlice = createSlice({
  name: "profileSettings",
  initialState,
  reducers: {
    // update profile settings
    updateProfileSettings: (state) => {
      state.isLoading = true;
    },
    // reset states
    resetzProfileSettingsStates: (state) => {
      state.isLoading = false;
    },
  },
});

export const { updateProfileSettings, resetzProfileSettingsStates } =
  profileSettingsSlice.actions;
export default profileSettingsSlice.reducer;
