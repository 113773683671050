import { takeLatest, put } from "redux-saga/effects";
import { $host, $authHost } from "../axios";

import {
  getDashboardData,
  getDashboardDataSuccess,
  // Sign-ups
  getSignUps,
  getSignUpsSuccess,
  //
  resetDashboardDataStates,
} from "./dashboardSlice";

function* getDashboardSaga() {
  try {
    const response = yield $authHost.get(`/dashboard/admin`);
    yield put(getDashboardDataSuccess(response.data.data.analytics));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
      yield put(resetDashboardDataStates());
    } else {
      yield put(resetDashboardDataStates());
    }
  }
}

function* getSignUpsSaga(action) {
  const { year } = action.payload;

  let url = "/dashboard/admin/new-signups";

  if (year) {
    const params = new URLSearchParams();
    params.append("year", year);
    url += `?${params.toString()}`;
  }

  try {
    const response = yield $authHost.get(url);
    yield put(getSignUpsSuccess(response.data.data.analytics));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
      yield put(resetDashboardDataStates());
    } else {
      yield put(resetDashboardDataStates());
    }
  }
}

export default function* dashboardRootSaga() {
  yield takeLatest(getDashboardData.type, getDashboardSaga);
  yield takeLatest(getSignUps.type, getSignUpsSaga);
}
