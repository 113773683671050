import { takeLatest, put } from "redux-saga/effects";
import { AES, enc } from "crypto-js";
import { $host, $authHost } from "../axios";
import {
  changePassword,
  resetChangePasswordStates,
} from "./passwordChangeSlice";
import { logout } from "@store/auth/authSlice";
function* passwordChangeSaga(action) {
  const { formState, notify, navigate, setFormState } = action.payload;

  const payload = {
    old_password: formState.current_password,
    new_password: formState.confirm_password,
  };

  try {
    const response = yield $authHost.post(`/auth/change_password`, payload);
    notify(response.data.description, "success");
    setFormState({
      current_password: "",
      new_password: "",
      confirm_password: "",
    });
    // yield put(logout());
    yield put(resetChangePasswordStates());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      notify(errorPreview.description, "error");
      yield put(resetChangePasswordStates());
    } else {
      notify(error.message, "error");
      yield put(resetChangePasswordStates());
    }
  }
}

export default function* passwordChangeRootSaga() {
  yield takeLatest(changePassword.type, passwordChangeSaga);
}
