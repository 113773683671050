import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analyticsData: [],
  roomsRadiatorUsage: [],
  isLoading: false,
};

const residentialAnalyticsSlice = createSlice({
  name: "residentialAnalytics",
  initialState,
  reducers: {
    // get analytics
    getResidentialAnalytics: (state) => {
      state.isLoading = true;
    },
    getResidentialAnalyticsSuccess: (state, action) => {
      state.isLoading = true;
      state.analyticsData = action.payload;
    },

    // get room analytics
    getRoomAnalytics: (state) => {
      state.isLoading = true;
    },
    getRoomAnalyticsSuccess: (state, action) => {
      state.isLoading = true;
      state.roomsRadiatorUsage = action.payload;
    },

    // reset states
    resetResidentialAnalyticsStates: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  getResidentialAnalytics,
  getResidentialAnalyticsSuccess,
  getRoomAnalytics,
  getRoomAnalyticsSuccess,
  resetResidentialAnalyticsStates,
} = residentialAnalyticsSlice.actions;
export default residentialAnalyticsSlice.reducer;
