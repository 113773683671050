export const subscriptionTypes = [
  { value: "Free", label: "Free" },
  { value: "Premium", label: "Premium" },
  { value: "Standard", label: "Standard" },
];

export const mapSubscriptionTypeToValue = (type) => {
  switch (type) {
    case "Free":
      return 1;
    case "Premium":
      return 3;
    case "Standard":
      return 2;
    default:
      return 0;
  }
};

export const mapValueToSubscriptionType = (type) => {
  switch (type) {
    case 1:
      return "Free";
    case 3:
      return "Premium";
    case 2:
      return "Standard";
    default:
      return 0;
  }
};
